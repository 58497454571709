import React from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { number, oneOfType, string } from 'prop-types'
import { Link } from '@jelly/ui'

// Styled Components via Emotion
export const CardWrapper = styled(Link)`
	background: #fff;
	cursor: pointer;
	height: 100%;
	max-height: 300px;
	overflow: hidden;
	position: relative;
	width: 100%;
	${({ gridBrowserSupport }) => gridBrowserSupport};

	&:hover {
		span {
			color: ${({ theme }) => theme.colors.navy};
		}

		${({ theme }) => theme.MQ.M} {
			.onHover {
				color: #fff;
			}
			div {
				margin-top: 0;
			}
		}
	}

	${({ theme }) => theme.MQ.XL} {
		height: 300px;
		width: 390px;
	}
`
export const ContentWrapper = styled.div`
	padding: 50px 20px;

	${({ theme }) => theme.MQ.M} {
		position: absolute;
		margin: 0 auto;
	}
`
const DateWrapper = styled.span`
	${({ theme }) => theme.text.sectionHeaderSmall};
	transition: all 1.5s ease;
`
const TitleWrapper = styled.div`
	${({ theme }) => theme.header.h3};
	color: ${({ theme }) => theme.colors.navy};
	margin: 10px 0;
	transition: all 1.5s ease;

	${({ theme }) => theme.MQ.M} {
		${({ theme }) => theme.header.h3};
		margin: 0;
		margin-bottom: 5px;
		max-width: 290px;
		width: 100%;
	}
`
const Cta = styled.div`
	${({ theme }) => theme.text.sectionHeaderSmall};
	color: ${({ theme }) => theme.colors.orange};
`
const Slider = styled.div`
	display: none;

	${({ theme }) => theme.MQ.M} {
		background: ${({ theme }) => theme.colors.teal};
		display: block;
		height: 95vh;
		margin-top: 290px;
		transition: all 1.5s ease;
		width: 100%;
	}
`
const SponsoredBadge = styled.div`
	color: ${({ theme }) => theme.colors.gray[500]};
	font-size: 12px;
	margin-bottom: 10px;
`

CardShortContent.propTypes = {
	date: oneOfType([string, number]),
	gridBrowserSupport: string,
	title: string,
	url: string,
}

/**
 * This Component is the Short Content Card
 * It takes in the most minimal amount of data:
 * Title, Date, Link
 * On Hover: a teal overlay slides up from the bottom of the card
 * The Title goes from Navy to White, and date goes from Teal to Navy
 */
function CardShortContent({ date, gridBrowserSupport, title, url, sponsored }) {
	return (
		<CardWrapper href={url} gridBrowserSupport={gridBrowserSupport}>
			<ContentWrapper>
				{sponsored && <SponsoredBadge>Sponsored Content</SponsoredBadge>}
				<DateWrapper>{dayjs(date).format('MM.DD.YY')}</DateWrapper>
				<TitleWrapper className="onHover">{title}</TitleWrapper>
				<Cta>Full Article</Cta>
			</ContentWrapper>
			<Slider />
		</CardWrapper>
	)
}

export default CardShortContent
