import React from 'react'
import styled from '@emotion/styled'
import { string, element } from 'prop-types'

// Styled Components via Emotion
const Wrapper = styled.div`
	background: ${({ background }) => background};
	display: block;
	text-align: center;
	width: 100%;

	${({ theme }) => theme.MQ.M} {
		display: inline-block;
		width: 33.33%;
	}
`
const CtaWrapper = styled.div`
	padding: 2rem;
`
const IconWrapper = styled.div`
	margin: auto;
`
const Title = styled.div`
	${({ theme }) => theme.header.h3};
	color: ${({ theme }) => theme.colors.primary};
	margin: 1rem 0;
`
const Description = styled.div`
	margin: 1rem 0;
`

CardCta.propTypes = {
	background: string,
	cta: string,
	description: string,
	icon: element,
}

/**
 * This Component is the CTA Card
 *
 * @param {String} props.background :: background color
 * @param {String} props.cta :: title for the card
 * @param {String} props.description :: description for the card
 * @param {Element} props.icon :: svg image to use for the card
 */
function CardCta({ background, cta, description, icon }) {
	return (
		<Wrapper background={background}>
			<CtaWrapper>
				<IconWrapper>{icon}</IconWrapper>
				<Title> {cta} </Title>
				<Description> {description} </Description>
			</CtaWrapper>
		</Wrapper>
	)
}

export default CardCta
