import React from 'react'
import styled from '@emotion/styled'
import { string, bool } from 'prop-types'

// Import utils
import { makeImgResizerUrl } from '@jelly/utils'

// Import Components
import { HoverPlusArrowWrapper, HoverPlusArrow, PlusWrapper } from '@jelly/components'
import { Link } from '@jelly/ui'

// Styled Components via Emotion
const CardWrapper = styled(Link)`
	color: #fff;
	cursor: pointer;
	display: block;
	height: 33vh;
	min-height: 200px;
	max-height: 200px;
	max-width: 600px;
	overflow: hidden;
	position: relative;
	width: 100%;

	&:hover {
		color: ${({ theme }) => theme.colors.white};

		.OverlayWrapper {
			margin-left: 0;
		}
		.Header {
			padding: 135px 30px;
		}
		${PlusWrapper} {
			display: none;
		}
		${HoverPlusArrowWrapper} {
			display: block;
		}
	}
`
const ImgWrapper = styled.div`
	background-position: center right;
	height: 100%;
	position: absolute;
	transform: ${({ flip }) => (flip ? 'scaleX(-1)' : '')};
	width: 100%;

	&.lazyloaded {
		background-image: url(${({ background }) => background});
	}
`
const LayerOverlay = styled.div`
	background-color: ${({ theme }) => theme.colors.tertiary};
	height: 100%;
	opacity: 0.75;
	position: absolute;
	width: 100%;
`
// CARD CONTENT //
const Header = styled.div`
	${({ theme }) => theme.header.h3};
	bottom: 0;
	margin: 0;
	margin-top: auto;
	padding: 10px 30px;
	position: absolute;
	transition: all 0.5s ease;
	z-index: 1;
`
const OverlayWrapper = styled.div`
	background: ${({ theme }) => theme.colors.tertiary};
	height: 100%;
	margin-left: -100%;
	position: absolute;
	transition: all 0.8s ease;
	width: 100%;
`
const DescriptionWrapper = styled.div`
	padding: 70px 30px;
	width: 80%;

	${({ theme }) => theme.MQ.L} {
		width: 60%;
	}
`

CardLongContent.propTypes = {
	background: string,
	description: string,
	flip: bool,
	header: string,
	url: string,
}

/**
 * This Component is the Long Content Card
 *
 * The width is 100% of the parent, but the height is only 33% of the parent height
 * Takes in an image, and sets it as a background with a teal overlay opacity of .75
 * Image also has an option of reflecting horizontally (flip prop)
 *
 * The whole card is clickable, On hover: there is a teal overlay-slide out from the left with 100% opacity
 * the title should slide up while a blurb slides out with the teal overlay-slide out
 */
function CardLongContent({ background, description, flip = false, header, url }) {
	const backgroundUrl = makeImgResizerUrl({
		src: background,
		method: 'w',
		w: 600,
	})

	return (
		<CardWrapper aria-label={header} href={url}>
			{/* Image background set */}
			<ImgWrapper background={backgroundUrl} flip={flip} className="lazyload" />
			<LayerOverlay />

			<Header className="Header">{header}</Header>

			{/* Arrow */}
			<HoverPlusArrow />

			{/* Teal Overlay on Hover */}
			<OverlayWrapper className="OverlayWrapper">
				<DescriptionWrapper>{description}</DescriptionWrapper>
			</OverlayWrapper>
		</CardWrapper>
	)
}

export default CardLongContent
