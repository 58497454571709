import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { number, string } from 'prop-types'

// Import utils
import { makeImgResizerUrl } from '@jelly/utils'

// Import Components
import { HoverPlusArrowWrapper, HoverPlusArrow, PlusWrapper } from '@jelly/components'
import { Link } from '@jelly/ui'

// Styled Components via Emotion
const Wrapper = styled(Link)`
	background-position: center;
	cursor: pointer;
	display: block;
	overflow: hidden;
	position: relative;
	${({ gridBrowserSupport }) => gridBrowserSupport};

	&.lazyloaded {
		background-image: url(${({ background }) => background});
	}

	&:hover {
		.categoryPanel {
			background: ${({ theme }) => theme.colors.navy};
			left: 0;

			.categoryDescription {
				display: block;
			}
		}
		${PlusWrapper} {
			display: none;
		}
		${HoverPlusArrowWrapper} {
			display: block;
		}
	}
`
const LayerOverlay = styled.div`
	background-color: ${({ theme }) => theme.colors.navy};
	height: 100%;
	opacity: 0.75;
	position: absolute;
	width: 100%;
`
const CategoryPanelWrapper = styled.span`
	cursor: pointer;
	height: 100%;
	position: absolute;
	right: 0;
	transition: all 0.8s ease;
	left: -100%;
	width: 100%;
`
const CategoryName = styled.div`
	color: #fff;
	display: block;
	font-size: 16px;
	padding: 20px 15px 0 15px;
	position: absolute;
	max-height: 60px;
	width: calc(100% - 30px);
	z-index: 1;

	${({ theme }) => theme.MQ.L} {
		font-size: 20px;
		padding: 40px 25px 0 25px;
		width: calc(100% - 50px);
	}
`
const CategoryDescription = styled.p`
	${({ theme }) => theme.fonts.bodySmall};
	color: #fff;
	display: none;
	margin-left: 15px;
	margin-right: 15px;
	margin-top: ${({ height }) => height * 1.2}px;
	font-size: 12px;

	@media (min-width: 850px) {
		font-size: 14px;
	}

	${({ theme }) => theme.MQ.L} {
		margin-left: 25px;
		margin-right: 25px;
		// mulitplied height to be more responsive than static number
		margin-top: ${({ height }) => height * 2}px;
	}

	${({ theme }) => theme.MQ.XL} {
		font-size: 16px;
	}
`

CardPhotoContent.propTypes = {
	background: string,
	category: string,
	description: string,
	gridBrowserSupport: string,
	id: number,
	url: string,
}

/**
 * This Component is the Photo Content Card
 * This card sets an image as the background
 * with a navy blue overlay at the opacity of .75
 * The entire card is clickable and should be a link
 * On Hover: a navy panel should slide out from the left to overlay the card
 * The title stays where it is, while a blurb slides in with the overlay
 */
function CardPhotoContent({ background, category, description, gridBrowserSupport, id, url }) {
	const [titleHeight, setTitleHeight] = useState()

	useEffect(() => {
		setTitleHeight(document.getElementById(`categoryTitle-${id}`).offsetHeight)
		window.addEventListener('resize', () => {
			setTitleHeight(document.getElementById(`categoryTitle-${id}`).offsetHeight)
		})
	}, [id, titleHeight])

	const backgroundUrl = makeImgResizerUrl({
		src: background,
		method: 'h',
		h: 300,
	})

	return (
		<Wrapper
			alt={category}
			background={backgroundUrl}
			className="lazyload"
			gridBrowserSupport={gridBrowserSupport}
			href={url}
		>
			<LayerOverlay />
			<CategoryName id={`categoryTitle-${id}`}>{category}</CategoryName>
			<CategoryPanelWrapper className="categoryPanel">
				<CategoryDescription className="categoryDescription" height={titleHeight}>
					{description}
				</CategoryDescription>
			</CategoryPanelWrapper>
			<HoverPlusArrow />
		</Wrapper>
	)
}

export default CardPhotoContent
